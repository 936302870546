import React, { useEffect, useState } from "react";
import { socketFunc } from "../../../../utils/socketUtil";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST } from "../../../../config/const";
import { Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";

const AtlasLobby = ({ isAuth = false }) => {
  const [iframeUrl, setIframeUrl] = useState("");
  var width = window.screen.width;
  const isMobile = width < 800;
  const socket = socketFunc(localStorage.getItem("token"));
  document.body.style.setProperty("--header-background", `#050505`);

  useEffect(() => {
    if (isAuth) {
      getLobbyUrl();
    } else {
      setIframeUrl("https://c225x.play-platform.xyz?token=null");
    }
  }, [isAuth]);

  const getLobbyUrl = async () => {
    socket.emit(
      "get:atlas_lobby",
      {
        mobile: isMobile,
      },
      (res) => {
        if (res?.status === "failed") {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        }
        if (res?.status === "success" && typeof res?.data === "string") {
          setIframeUrl(res?.data);
        }
      }
    );
  };

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}
      <main className="main live_casino_page live AtlasLobby">
        {!Boolean(iframeUrl) ? (
          <div className="custom_placeholder_parent">
            <div className="custom_placeholder_child">
              <Spinner animation="grow" variant="primary" size="sm" />
              <Spinner animation="grow" variant="primary" size="sm" />
              <Spinner animation="grow" variant="primary" size="sm" />
            </div>
          </div>
        ) : iframeUrl === "notAllowed" ? (
          <Navigate to="/home" />
        ) : iframeUrl === "notFound" ? (
          <Navigate to="/home" />
        ) : (
          <div className="exchange_iframe">
            <iframe
              title="Exchange"
              src={iframeUrl}
              frameBorder="0"
              // scrolling="no"
              allowFullScreen={true}
            >
              Your browser doesn't support iFrames.
            </iframe>
          </div>
        )}
      </main>
    </>
  );
};
export default AtlasLobby;
